import { useTranslation } from "react-i18next"
import { Button } from "components/general"
import Container from "components/layout/Container"

import styles from "./GiveMeTokens.module.scss"
import { useGiveMeTokensTx } from "txs/useGEDEX"


const GiveMeTokens = () => {
    const { t } = useTranslation()
    const { write } = useGiveMeTokensTx()

    const onSubmit = () => {
        write?.()
    }

    return (
        <Container className={styles.container}>
            <Button
                onClick={onSubmit}
                // {...attrs}
                // icon={submitting && <LoadingCircular size={18} />}
                // disabled={attrs.disabled || submitting}
                // className={styles.submit}
                color="default"
                >
                {t("Give Me Tokens!")}
            </Button>
            <div></div>
      </Container>
    )
}

export default GiveMeTokens
