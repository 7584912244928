import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useAccount, useSwitchNetwork } from 'wagmi'

/* helpers */
import BigNumber from "bignumber.js"
import { has } from "utils/num"

/* components */
import { Form, FormArrow, FormHelp, FormError } from "components/form"
import { Checkbox } from "components/form"
import { Read } from "components/token"

/* tx modules */
import validate from "../validate"
import Tx, { TxProps } from "./Tx"

/* swap modules */
import AssetFormItem from "./components/AssetFormItem"
import { AssetInput, AssetReadOnly } from "./components/AssetFormItem"
import { SelectCrosschainToken } from "./components/SelectToken"
import SlippageControl from "./components/SlippageControl"
import ExpectedPrice, { ExpectedPriceProps } from "./components/ExpectedPrice"


import { useCrossChainSwap } from "./CrossChainSwapContext"
import { useReadBalances, useCrosschainSwapSendInfo, useCrosschainSwapReceiveInfo } from "../useGEDEX"
import { defaultDecimals } from "config/assets.js"
import { defaultNetworkSymbol, getNetworkTokensList } from "config/tokens"
import { getGDXAddress } from "config/contracts"
import { defaultNetworkID, defaultDestNetworkID, networks } from "config/networks"

import { readAmount } from "@terra.kitchen/utils"
// import styles from "./SwapForm.module.scss"



interface TxValues {
  input?: number
  offerSymbol?: string
  askSymbol?: string
  sourceNetwork?: number
  destNetwork?: number
  slippageInput?: number
}


const SwapForm = () => {
  
  const { t } = useTranslation()
  const { address, isConnected } = useAccount()
  const { switchNetwork } = useSwitchNetwork()
  
  const { networkID, sourceTokensList } = useCrossChainSwap()
  
  const [destNetworkId, setDestNetwork] = useState(networkID === defaultNetworkID ? defaultDestNetworkID : defaultNetworkID)
  const [destTokensList, setDestTokensList] = useState(getNetworkTokensList(destNetworkId))
  
  const [showAll, setShowAll] = useState(false)

  const gdxDestAddress: string = getGDXAddress(destNetworkId)


  // BALANCES
  const { assetsList: sourceAssetsList, refetchTokens } = useReadBalances(sourceTokensList)
  const { assetsList: destAssetsList } = useReadBalances(destTokensList)
  


  // LISTA DE REDES DEL FORMULARIO
  const getNetworksOptions = () => {
    return [{ 
      title: t("Networks"), 
      children: networks.map((network) => {
        const { name: value } = network
        
        return { ...network, value }
      })
   }]
  }
  // LISTA DE TOKENS DEL FORMULARIO
  const getCoinsOptions = (key: "offerSymbol" | "askSymbol", netKey: "sourceNetwork" | "destNetwork") => {
    const assetsList = netKey === "sourceNetwork" ? sourceAssetsList : destAssetsList
    return [{ 
      title: t("Coins"), 
      children: assetsList.map((asset) => {
        const { symbol: value, balance } = asset

        // Sombrear en la lista, si hay un activo seleccionado en el otro
        // formulario, diferente a GEX, todas las opciones que no sean GEX 
        const muted = { 
          offerSymbol: !!askSymbol && (askSymbol==value) || (offerSymbol==value),
          askSymbol: !!offerSymbol && (offerSymbol==value) || (askSymbol==value)
        }[key]

        const hidden = key === "offerSymbol" && !showAll && !has(balance) && isConnected
        
        return { ...asset, value, muted, hidden }
      })
   }]
  }


  // FORM
  const form = useForm<TxValues>({
    mode: "onSubmit", // what triggers validation of form inputs. onChange is expensive. Alt: onSubmit
    defaultValues: { 
      offerSymbol: defaultNetworkSymbol[networkID], 
      askSymbol: defaultNetworkSymbol[destNetworkId], 
      sourceNetwork: networkID, 
      destNetwork: destNetworkId,
      slippageInput: 1,
    },
  })

  const { register, trigger, watch, setValue, resetField, handleSubmit, reset, formState } = form
  const { errors } = formState
  const { offerSymbol, askSymbol, input, sourceNetwork, destNetwork, slippageInput } = watch() // Esta desestructuración viene definida por el interfaz TxValues
  


  if (Number.isNaN(input)) resetField("input")  // A veces aparece un NaN, causa desconocida.


  // Change assets position in the form
  const swapAssets = () => {
    setValue("sourceNetwork", destNetwork)
    setValue("destNetwork", sourceNetwork)
    setDestNetwork(sourceNetwork ?? networkID)
    setDestTokensList(getNetworkTokensList(sourceNetwork ?? networkID))
    switchNetwork?.(destNetwork)
    setValue("offerSymbol", askSymbol)
    setValue("askSymbol", offerSymbol)
    
    resetField("input")
  }

  const updateBalances = () => {
    if (!isConnected) return
    
    (async () => {
      const {data: tokensBalances} = await refetchTokens()
      
      if (tokensBalances) {
        tokensBalances.forEach((balance, index) => {
          sourceAssetsList[index].balance = balance ? balance.toString() : sourceAssetsList[index].balance
        })
      }
    })()
  }
  
  useEffect(() => {
    updateBalances()
  }, [offerSymbol, askSymbol])

  const offerAssetItem = offerSymbol ? findAssetBySymbol(offerSymbol, sourceAssetsList, sourceAssetsList[0]) : sourceAssetsList[0]
  const offerDecimals = offerAssetItem ? offerAssetItem.decimals : defaultDecimals
  const askAssetItem = askSymbol ? findAssetBySymbol(askSymbol, destAssetsList, destAssetsList[0]) : destAssetsList[1]
  const askDecimals = askSymbol ? findAssetDecimalsBySymbol(askSymbol, destAssetsList, destAssetsList[0]) : defaultDecimals

  const inAmount = new BigNumber(input ?? 0).shiftedBy(offerDecimals).toFixed()



  // FETCH SWAP ESTIMATES
  const enableHooks = isConnected && !!sourceNetwork && !! destNetwork && !!offerSymbol && !!askSymbol && !!input 
  const sendInfo = useCrosschainSwapSendInfo(offerAssetItem.address, askAssetItem.address, inAmount, undefined, askAssetItem.chainId, enableHooks)
  const { offerAssetPrice, askAssetPrice, askAssetRatio, feePerc, feeAmount, crosschainFeeAmount, gdxAmount, priceImpact } = sendInfo
  const { outAmount } = useCrosschainSwapReceiveInfo(gdxDestAddress, askAssetItem.address, gdxAmount, askAssetItem.chainId, enableHooks)
  
  const minReceive = calcMinimumReceived(outAmount ?? "0", slippageInput ?? 1)
  const receiver = address ?? ""
    

  
  // Handle select network on form
  const [wrongNetwork, setWrongNetwork] = useState(false)

  const onSelectNetwork = (key: "sourceNetwork" | "destNetwork") => {
    return async (value: number) => {
      const networks = {
        sourceNetwork: { sourceNetwork: value, destNetwork },
        destNetwork: { sourceNetwork, destNetwork: value },
      }[key]

      // empty opposite network if select the same asset
      if (networks.sourceNetwork === networks.destNetwork) {
        setValue(key === "sourceNetwork" ? "destNetwork" : "sourceNetwork", undefined)
      }

      setValue(key, value)
      if (key === "sourceNetwork") {
        switchNetwork?.(value)
      } else {
        setDestNetwork(value)
      }

      if (networks.sourceNetwork != networkID) {
        setWrongNetwork(true)
      } else {
        setWrongNetwork(false)
      }
    }
  }
  

  // Handle select asset on form
  const onSelectAsset = (key: "offerSymbol" | "askSymbol") => {
    return async (value: string) => {
      const assets = {
        offerSymbol: { offerSymbol: value, askSymbol },
        askSymbol: { offerSymbol, askSymbol: value },
      }[key]

      // empty opposite asset if select the same asset
      if (assets.offerSymbol === assets.askSymbol) {
        setValue(key === "offerSymbol" ? "askSymbol" : "offerSymbol", undefined)
      }
      
      // focus on input if select offer asset
      if (key === "offerSymbol") {
        form.resetField("input")
        form.setFocus("input") // Pone el cursor para escribir en el formulario 1. Como hacer click.
      }

      setValue(key, value)
    }
  }

  


  // tx
  // PARÁMETROS QUE SE PASAN AL COMPONENTE TX
   const txProps: TxProps = {
    offerAssetItem,
    askAssetItem,
    inAmount,
    nativeFee: crosschainFeeAmount,
    outAmount,
    feeAmount,
    minReceive, 
    receiver, 
    priceImpact,
    resetForm: () => reset(),
    updateBalances,
    networkID,
    isConnected
  }

  const tx = {
    newProps: txProps,
    onPost: () => {} // Función para añadir token personalizado al wallet tras comprarlo -> implementar,
  }

  // No está muy claro que tenga algún efecto: en el componente Tx se define de nuevo
  // const disabled = enableHooks ? false: "Input data" //isFetching ? t("Simulating...") : false
  const disabled = false // Introducir texto para mostrarlo en el banner de advertencia. Se puede hacer también desde el componente Tx y desactivar de paso el botón de submit.



  // RENDER FUNCTIONS
  const isFetching = false

  // type guard
  const validateExpectedPriceProps = (
    params: Partial<ExpectedPriceProps>
    ): params is ExpectedPriceProps => {
      const { offerSymbol, askSymbol, offerAssetPrice, askAssetPrice, 
        askAssetRatio, feeAmount } = params
      return !!(offerSymbol && askSymbol && offerAssetPrice && 
        askAssetPrice && askAssetRatio && feeAmount)
    }

  // render: expected price
  const renderExpected = () => {
    if (!input) return null

    const isLoading = false
    const props = { offerSymbol, offerDecimals, askSymbol, askDecimals, 
      offerAssetPrice, askAssetPrice, askAssetRatio, feeAmount, isLoading, mode:"Swap"}

    if (!(isConnected && validateExpectedPriceProps(props))){
      // console.log("[SWAPFORM][renderExpected] props NOT VALIDATED", props)
      return null
    } 
    // (7-10) DATOS DEBAJO DEL PAR, APARECEN SOLO
    // SI SE INTRODUCE UNA CANTIDAD ARRIBA PARA CAMBIAR
    // console.log("[SWAPFORM][renderExpected] props VALIDATED", props)
    return <ExpectedPrice {...props} isLoading={isFetching} />
  }

    

  return (
    <Tx {...tx} disabled={disabled}>
      {({ max, fee, submit }) => (
        <Form onSubmit={handleSubmit(submit.fn)}>
          {/* (1) PESTAÑAS MERCADOS SWAP */}
          {/* {renderRadioGroup()} */}
        
          {/* (3) PRIMER FORMULARIO TOKEN */}
          <AssetFormItem
            label={t("From")}
            extra={max.render(async (value) => {// (2) BALANCE TOKEN 1 (MÁXIMO GASTABLE)
              // Do not use automatic max here
              // Confusion arises as the amount changes and simulates again
              setValue("input", toInput(value, offerDecimals))
              await trigger("input")
            })}
            error={errors.input?.message}
          >
            
            <SelectCrosschainToken
              selectedToken={offerSymbol}
              selectedNetworkID={sourceNetwork}
              onChangeToken={onSelectAsset("offerSymbol")}
              onChangeNetwork={onSelectNetwork("sourceNetwork")}
              options={getCoinsOptions("offerSymbol", "sourceNetwork")}
              networkOptions={getNetworksOptions()}
              checkbox={
                <Checkbox
                  checked={showAll}
                  onChange={() => setShowAll(!showAll)}
                >
                  {t("Show all")}
                </Checkbox>
                
              }
              addonAfter={
                <AssetInput
                  {...register("input", {
                    valueAsNumber: true,
                    validate: validate.input(
                      toInput(max.amount, offerDecimals),
                      offerDecimals
                    ),
                  })}
                  inputMode="decimal"
                  placeholder={"0.000"}
                  onFocus={max.reset}
                  autoFocus
                />
              }
            />
          </AssetFormItem>

          {wrongNetwork && <FormHelp>{t("Switch network on your wallet")}</FormHelp>}

          {/* (4) FLECHA INVERTIR SWAP */}
          <FormArrow onClick={swapAssets} />

          {/* (5) SEGUNDO FORMULARIO TOKEN */}
          <AssetFormItem label={t("To")}>
            <SelectCrosschainToken
              selectedToken={askSymbol}
              selectedNetworkID={destNetwork}
              onChangeToken={onSelectAsset("askSymbol")}
              onChangeNetwork={onSelectNetwork("destNetwork")}
              options={getCoinsOptions("askSymbol", "destNetwork")}
              networkOptions={getNetworksOptions()}
              addonAfter={
                <AssetReadOnly>
                  <Read
                    amount={outAmount}
                    decimals={askDecimals}
                    approx
                  />
                </AssetReadOnly>
              }
            />
          </AssetFormItem>

          {isConnected && (
            <SlippageControl
              {...register("slippageInput", {
                valueAsNumber: true,
                validate: validate.input(50, 2, "Slippage tolerance"),
              })}
              input={slippageInput} // to warn
              inputMode="decimal"
              placeholder={getPlaceholder(2)}
              error={errors.slippageInput?.message}
            />
          )}

          {/* (7-10) DATOS RESULTADO SWAP */}
          {renderExpected()}
          
          {/* (11) DETALLES FEE. COMPONENTE renderFee() DE Tx */}
          {/* {isConnected && fee.render()} */}

          {/* {( Banner rojo error parte inferior caja)} */}
          {disabled && <FormError>{disabled}</FormError>}
          

          {/* (12) BOTÓN ENVIAR TRANSACCIÓN */}
          {submit.button}
        </Form>
      )}
    </Tx>
  )
}


export default SwapForm


// HELPERS
const findAssetBySymbol = (symbol: string, assetsList: TokenItem[], defaultAsset: TokenItem) => {
  return assetsList.find((item) => item.symbol === symbol) ?? defaultAsset
}
const findAssetDecimalsBySymbol = (symbol: string, assetsList: TokenItem[], defaultAsset: TokenItem) => {
  return findAssetBySymbol(symbol, assetsList, defaultAsset).decimals
}


const calcMinimumReceived = (expOutAmount: string, slippageInput: number) => {
  const max_spread = new BigNumber(slippageInput).div(100).toString()
  const minRatio = new BigNumber(1).minus(max_spread)
  // console.log("[calcMinimumReceived] slippageInput, max_spread, minRatio", slippageInput, max_spread, minRatio.toString())
  const value = new BigNumber(expOutAmount).times(minRatio).toFixed(0)
  // const round_value = value.integerValue(BigNumber.ROUND_FLOOR).toString()
  // console.log("[calcMinimumReceived] expOutAmount, value, round_value", expOutAmount, value)//, round_value)
  return value
}

const toInput = (amount: BigNumber.Value, decimals = 6) =>
  new BigNumber(readAmount(amount, { decimals })).toNumber()

export const getPlaceholder = (decimals = 6) => "0.".padEnd(decimals + 2, "0")
