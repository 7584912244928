import { Trans } from "react-i18next"
import { Button } from "components/general"

import { getTokenItem } from "config/tokens"



interface Props {
    networkID: number
    tokenSymbol: string
}

const AddToken = ({networkID, tokenSymbol}: Props) => {
    const token = getTokenItem(networkID, tokenSymbol)

    if (!token) return null

    function addToken() {
        (async () => {
            if (window.ethereum && typeof token !== "undefined") {
                await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: token.address,
                            symbol: token.symbol,
                            decimals: token.decimals,
                            image: token.logoURI, // A string url of the token logo
                        },
                    },
                })
            }
        })()
    }

    
    return (
        <Button onClick={addToken} size="small" block>
            <Trans>
                Add {{tokenSymbol}} to wallet
            </Trans>
        </Button>
    )
}

export default AddToken
