import { validNetworkID, defaultNetworkID } from "./networks"

// import mainnetTokens from "./tokens/mainnet_tokens.json"
import testnetTokens from "./tokens/testnet_tokens.json"



export const chainsTokens: {[key: number]: TokenItem[]} = testnetTokens.tokens

export const defaultNetworkSymbol: {[key: number]: string} = {
    // mainnets
    1: "ETH", 
    10: "ETH",
    56: "BNB",
    137: "MATIC",
    42161: "ETH",
    43114: "AVAX",
    // testnets
    5: "ETH",
    97: "BNB",
    43113: "AVAX",
    80001: "MATIC"
  }


export const getNetworkTokensList = (networkID: number) => {
    const validId = validNetworkID.includes(networkID) ? networkID : defaultNetworkID
    return chainsTokens[validId]
}

export const getTokenItem = (networkID: number, tokenSymbol: string) => {
    const tokensList = getNetworkTokensList(networkID)
    return tokensList.find((item) => item.symbol === tokenSymbol)
}

// https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline
// const newChainsTokens = Object.entries(chainsTokens).map(([network, tokenslist]) => {
//     const newTokensList = tokenslist.map((token) => {
//         return {
//             ...token, 
//             icon: ""
//         }
//     })
//     return {network: newTokensList}
// })