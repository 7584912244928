import BigNumber from "bignumber.js"

import { useNetwork, useAccount, useBalance, useContractRead, useContractReads, erc20ABI } from 'wagmi'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

import { getGEDEXContract, getGiveMeTokensContract } from "config/contracts.js"
import { lzChainId } from "config/bridges"
import { defaultNetworkID } from "config/networks"



export const useReadBalances = (tokensList: TokenItem[]) => {
  // console.log("[useReadBalances] tokensList", tokensList)
  const { address, isConnected } = useAccount()

  const contracts = tokensList.map((asset) => {
    return {
      addressOrName: asset.address,
      contractInterface: erc20ABI,
      functionName: "balanceOf",
      args: address,
      enabled: isConnected
    }
  })
  
  const { data, refetch } = useContractReads({ contracts })
  
  const tokensPool: TokenItem[] = tokensList.map((asset, index) => {
    // console.log("[useReadBalances] asset:",asset.symbol, " balance:", new BigNumber(data?.[index]?.toString() ?? "0").toFixed(0))
    return {
      ...asset, 
      balance: new BigNumber(data?.[index]?.toString() ?? "0").toFixed(0)
    }
  })
  // console.log("[useReadBalances] assetsList", tokensPool)

  return {assetsList: tokensPool, refetchTokens: refetch}
}


// export const useReadBalances2 = (nativeAsset: AssetEVM, tokensList: TokenEVM[]) => {
//   // console.log("[useReadBalances] nativeAsset, tokensList", nativeAsset, tokensList)
//   const { address, isConnected } = useAccount()

//   const contracts = tokensList.map((asset) => {
//     return {
//       addressOrName: asset.address,
//       contractInterface: erc20ABI,
//       functionName: "balanceOf",
//       args: address,
//       enabled: isConnected
//     }
//   })
  
//   const { data: coinData, refetch: refetchNative } = useBalance({addressOrName: address, formatUnits: 'wei'})
//   const { data: tokenData, refetch: refetchTokens } = useContractReads({ contracts })
  
//   const poolNative: PoolAsset = {
//     ...nativeAsset, 
//     balance: coinData?.formatted ?? "0"
//   }

//   const tokensPool: PoolAsset[] = tokensList.map((asset, index) => {
//     // console.log("[useReadBalances] asset / balance", asset.symbol, new BigNumber(tokenData?.[index]?.toString() ?? "0").shiftedBy(18-asset.uwdecimals).toFixed(0))
//     return {
//       ...asset, 
//       balance: new BigNumber(tokenData?.[index]?.toString() ?? "0").shiftedBy(18-asset.uwdecimals).toFixed(0)
//     }
//   })
  
//   const assetsList = [poolNative, ...tokensPool]

//   // console.log("[useReadBalances] assetsList", assetsList)

//   return {assetsList, refetchNative, refetchTokens}
// }


// export const useStableAsset = (offerAsset: TokenEVM, askAsset: TokenEVM) => {
//   return offerAsset.key == "gex" ? askAsset : offerAsset
// }

const useGEDEXContract = () => {
  const { chain } = useNetwork()
  const connectedNetworkId = chain?.id.toString() ?? defaultNetworkID
  const gedex = getGEDEXContract(connectedNetworkId)
  
  return { 
    address: gedex.address, 
    abi: gedex.abi, 
  }
}

// export const useMinterInfo = (offerSymbol:string, offerAmount:string, stablecoinAddress:string, enabled:boolean) => {
//   const { isConnected } = useAccount()
//   const { address, abi } = useGEDEXContract()
//   // console.log("[useMinterInfo] params:", offerSymbol, offerAmount, stablecoinAddress, enabled)
  
//   const contract = offerSymbol === "GEX" ? 
//   {
//     addressOrName: address,
//     contractInterface: abi,
//     functionName: "getMintInfo",
//     args: [offerAmount, stablecoinAddress],
//     enabled: isConnected && enabled
//   } : {
//     addressOrName: address,
//     contractInterface: abi,
//     functionName: "getRedeemInfo",
//     args: [offerAmount, stablecoinAddress],
//     enabled: isConnected && enabled
//   }

//   const { data, refetch, ...status } = useContractRead(contract)
//   // console.log("[useMinterInfo] status, contract Read:", status, data)
//   // if (status.error) // console.log("[useMinterInfo] Contract read ERROR:", data, status.error)
  
//   return { 
//     offerAssetPrice: data?.[0].toString(),
//     askAssetPrice: data?.[1].toString(),
//     feePerc: data?.[2].toString(),  // Number as string in 1e6 units
//     feeAmount: data?.[3].toString(),
//     outAmount: data?.[4].toString()
//   }
// }


export const useCrosschainSwapSendInfo = (offerAddress:string, askAddress:string, inAmount:string, 
  toAddress: string|undefined, destChainId:number, enabled:boolean
) => {
  const { address: userAddress, isConnected } = useAccount()
  const { address, abi } = useGEDEXContract()
  
  console.log("[useCrosschainSwapSendInfo] params:", offerAddress, askAddress, inAmount, toAddress, destChainId, enabled)
  const bridgeChainId = lzChainId[destChainId]
  const addrReceiver = toAddress ?? userAddress
  
  const contract = {
    addressOrName: address,
    contractInterface: abi,
    functionName: "getCrosschainSwapInfo",
    args: [offerAddress, askAddress, inAmount, addrReceiver, bridgeChainId],
    enabled: isConnected && enabled
  }

  const { data, refetch, ...status } = useContractRead(contract)
  console.log("[useCrosschainSwapSendInfo] status:", status, "contract Read:", data)
  // if (status.error) // console.log("[useSwapInfo] Contract read ERROR:", data, status.error)
  
  return { 
    offerAssetPrice: data?.[0].toString(),
    askAssetPrice: data?.[1].toString(),
    askAssetRatio: data?.[2].toString(),
    feePerc: data?.[3].toString(),  // Number as string in 1e6 units
    feeAmount: data?.[4].toString(),
    crosschainFeeAmount: data?.[5].toString(),
    gdxAmount: data?.[6].toString(),
    priceImpact: data?.[7].toString(),
  }
}

export const useCrosschainSwapReceiveInfo = (gdxAddress:string, askAddress:string, gdxAmount:string, destChainId:number, enabled:boolean) => {
  const { isConnected } = useAccount()
  const { address, abi } = useGEDEXContract()
  console.log("[useCrosschainSwapReceiveInfo] params:", gdxAddress, askAddress, gdxAmount, enabled)
  
  const contract = {
    addressOrName: address,
    contractInterface: abi,
    functionName: "simulateSwap",
    args: [gdxAddress, askAddress, gdxAmount],
    chainId: destChainId,
    enabled: isConnected && enabled && !!gdxAmount
  }

  const { data, refetch, ...status } = useContractRead(contract)
  console.log("[useCrosschainSwapReceiveInfo] status, contract Read:", status, data)
  // if (status.error) // console.log("[useSwapInfo] Contract read ERROR:", data, status.error)
  
  return { outAmount: data?.toString() }
}



export const useFetchAllowance = (tokenAddress:string, enabled:boolean) => {
  // console.log("[useFetchAllowance] tokenAddress, enabled:", tokenAddress, enabled)
  const { address, isConnected } = useAccount()
  const { address: gedexAddress } = useGEDEXContract()
  
  const contract = {
      addressOrName: tokenAddress ?? "",
      contractInterface: erc20ABI,
      functionName: "allowance",
      args: [address, gedexAddress],
      enabled: isConnected && enabled && !!tokenAddress
    }
  
  const { data, refetch, ...status } = useContractRead(contract)
  // console.log("[useFetchAllowance] allowedAmount, status:", data, status)
  
  return {allowedAmount: data?.toString(), refetchAllowance: refetch}
}
  
  
export const useInfiniteApprove = (tokenAddress:string, enabled:boolean) => {
  // console.log("[useInfiniteApprove] tokenAddress, enabled:", tokenAddress, enabled)  
  const { isConnected } = useAccount()
  const { address } = useGEDEXContract()

  const maxUint256 = new BigNumber(2).exponentiatedBy(256).minus(1).toFixed()
  
  const contract = {
    addressOrName: tokenAddress,
    contractInterface: erc20ABI,
    functionName: "approve",
    args: [address, maxUint256],
    enabled: isConnected && enabled
  }
  
  const { config, ...prepareState } = usePrepareContractWrite(contract)
  // if (prepareState.error) // console.log("[useInfiniteApprove] Prepare contract ERROR:", prepareState.error)
  
  const { data, write, ...writeState } = useContractWrite(config)
  
  const { ...waitState } = useWaitForTransaction({ 
    hash: data?.hash,
    confirmations: 1, 
  })
  
  const state = combineState(writeState, waitState)
  // console.log("[useInfiniteApprove] Combined state, enabled:", state, enabled)

  return { write, ...state }
}
  
  
export const useSubmitCrosschainSwapTx = (offerAddress:string, askAddress:string, inAmount:string, nativeFee: string,
  minReceive:string|undefined, toAddress: string|undefined, destChainId:number, enabled:boolean
  ) => {
  console.log("[useSubmitCrosschainSwapTx] offerSymbol, inAmount, stablecoinAddress, enabled:", offerAddress, askAddress, inAmount, minReceive, toAddress, enabled)
  const { address: userAddress, isConnected } = useAccount()
  const { address, abi } = useGEDEXContract()

  const addrReceiver = toAddress ?? userAddress
  const bridgeChainId = lzChainId[destChainId]

  const contract = {
    addressOrName: address,
    contractInterface: abi,
    functionName: "crosschainSwapSend",
    args: [offerAddress, askAddress, inAmount, minReceive ?? "0", addrReceiver, bridgeChainId],
    enabled: isConnected && enabled,
    overrides: {value: nativeFee}
  }

  const { config, ...prepareState } = usePrepareContractWrite(contract)
  if (prepareState.error) {
    console.log("[useSubmitCrosschainSwapTx] Prepare contract ERROR:", prepareState.error)
    console.log("[useSubmitCrosschainSwapTx] Prepare contract CONFIG:", config)
  }
  
  const { data, write, ...writeState } = useContractWrite(config)
  
  const { ...waitState } = useWaitForTransaction({ 
    hash: data?.hash,
    confirmations: 1, 
  })
  
  const state = combineState(writeState, waitState)
  console.log("[useSubmitCrosschainSwapTx] Combined state, enabled:", state, enabled)

  return { write, ...state }
}


// export const useSubmitSwapTx = (offerAddress:string, askAddress:string, inAmount:string, enabled:boolean) => {
//   // console.log("[useSubmitSwapTx] offerAddress, askAddress, inAmount, enabled:", offerAddress, askAddress, inAmount, enabled)
//   const { isConnected } = useAccount()
//   const { address, abi } = useGEDEXContract()

//   const contract = {
//     addressOrName: address,
//     contractInterface: abi,
//     functionName: 'stableSwap',
//     args: [offerAddress, askAddress, inAmount],
//     enabled: isConnected && enabled
//   }

//   const { config, ...prepareState } = usePrepareContractWrite(contract)
//   if (prepareState.error) {
//     // console.log("[useSubmitSwapTx] Prepare contract ERROR:", prepareState.error)
//     // console.log("[useSubmitSwapTx] Prepare contract CONFIG:", config)
//   }
  
//   const { data, write, ...writeState } = useContractWrite(config)
  
//   const { ...waitState } = useWaitForTransaction({ 
//     hash: data?.hash,
//     confirmations: 1, 
//   })
  
//   const state = combineState(writeState, waitState)
//   // console.log("[useSubmitSwapTx] Combined state, enabled:", state, enabled)

//   return { write, ...state }
// }


const useGiveMeTokensContract = () => {
  const { chain } = useNetwork()
  const connectedNetworkId = chain?.id.toString() ?? defaultNetworkID
  const contract = getGiveMeTokensContract(connectedNetworkId)
  
  return { 
    address: contract.address, 
    abi: contract.abi, 
  }
}

export const useGiveMeTokensTx = () => {
  const { isConnected } = useAccount()
  const { address, abi } = useGiveMeTokensContract()


  const contract = {
    addressOrName: address,
    contractInterface: abi,
    functionName: "giveMeTokens",
    enabled: isConnected,
  }

  const { config, ...prepareState } = usePrepareContractWrite(contract)
  if (prepareState.error) {
    console.log("[useGiveMeTokensTx] Prepare contract ERROR:", prepareState.error)
    console.log("[useGiveMeTokensTx] Prepare contract CONFIG:", config)
  }
  
  const { data, write, ...writeState } = useContractWrite(config)
  
  const { ...waitState } = useWaitForTransaction({ 
    hash: data?.hash,
    confirmations: 1, 
  })
  
  const state = combineState(writeState, waitState)
  console.log("[useGiveMeTokensTx] Combined state, enabled:", state)

  return { write, ...state }
}


/* helpers */
const combineState = (...results: QueryState[]) => ({
  isIdle: results.some((result) => result.isIdle),
  isLoading: results.some((result) => result.isLoading),
  isFetching: results.some((result) => result.isFetching),
  isSuccess: results.every((result) => result.isSuccess),
  error: results.find((result) => result.error)?.error,
})