import { PropsWithChildren, useMemo, useState } from "react"
import { useAccount, useNetwork } from 'wagmi'

import { Card } from "components/layout"
import createContext from "utils/createContext"

import { getNetworkTokensList } from "config/tokens"
import { defaultNetworkID, defaultDestNetworkID } from "config/networks"


interface CrossChainSwap {
    networkID: number
    // destNetworkId: number
    sourceTokensList: TokenItem[]
    // destTokensList: TokenItem[]
    // setDestNetwork: (networkID: number) => void
}

export const [useCrossChainSwap, CrossChainSwapProvider] = createContext<CrossChainSwap>("useCrossChainSwap")


const CrossChainSwapContext = ({ children }: PropsWithChildren<{}>) => {
    console.log("+++ [CrossChainSwapContext]")
    const { isConnected } = useAccount()
    const { chain } = useNetwork()

    const networkID = chain?.id ?? defaultNetworkID

    const context = useMemo(() => {
        const sourceTokensList = getNetworkTokensList(networkID)
        
        return { networkID, sourceTokensList }
    }, [chain])


    const cardKey = networkID + (isConnected ? 1 : 0)

    const render = () => {
        if (!context) return null
        return <CrossChainSwapProvider value={context}>{children}</CrossChainSwapProvider>
    }

return <Card key={cardKey}>{render()}</Card>
}


export default CrossChainSwapContext
