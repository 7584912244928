/* website */
export const GEDEX_WEB = "https://gedex.nexus"
export const TUTORIAL = "https://gedex.nexus"

/* media and sites */
export const MEDIUM = ""
export const TWITTER = "https://twitter.com/GE_DEX"
export const GITHUB = "https://github.com"
export const TELEGRAM_ANN = "https://t.me/gedex_ann"
export const TELEGRAM_GROUP = "https://t.me/gedex_eng"
