export const lzChainId: {[key: number]: number} = {
    // mainnets
    1: 101, // Ethereum
    10: 111, // Optimism
    56: 102, // BNB Chain
    137: 109, // Polygon
    42161: 110, // Arbitrum
    43114: 106, // Avalanche
    // testnets
    5: 10121, // ETH Goerli
    97: 10102, // BSC Test
    43113: 10106, // Avalanche Fuji
    80001: 10109 // Polygon Mumbai
}

