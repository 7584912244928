import { useThemeLogo } from "data/settings/Theme"
import defaultLogo from "styles/images/gedex/gedex_black.png"
import { GEDEX_WEB } from "config/constants"
import classNames from "classnames"
import styles from "./Logo.module.scss"


const Logo = () => {
    const gedexLogo = useThemeLogo() ?? defaultLogo

    return (
        <a href={GEDEX_WEB} className={styles.logo}>
            <img src={gedexLogo} style={{height: "50px"}}/>
        </a>
    )
  }
  
  export default Logo
  