import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { flatten } from "ramda"
import classNames from "classnames/bind"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import SearchIcon from "@mui/icons-material/Search"
import createContext from "utils/createContext"
import { Flex, Grid } from "components/layout"
import { Empty } from "components/feedback"
import { TokenCard, TokenCardGrid, TokenIcon } from "components/token"

import styles from "./SelectToken.module.scss"

const cx = classNames.bind(styles)


interface TokenGroupProps {
  title: string
  children: TokenItemProps[]
}

const SelectTokenGroup = ({ title, children }: TokenGroupProps) => {
  return !children.filter(({ hidden }) => !hidden).length ? null : (
    <article>
      <h1 className={styles.title}>{title}</h1>
      <TokenCardGrid>
        {children.map((item) => (
          <SelectTokenItem {...item} key={item.value} />
        ))}
      </TokenCardGrid>
    </article>
  )
}


interface NetworkGroupProps {
  title: string
  children: Network[]
}

const SelectNetworkGroup = ({ title, children }: NetworkGroupProps) => {
  return (
    <article>
      <h1 className={styles.title}>{title}</h1>
      <TokenCardGrid>
        {children.map((item) => (
          <SelectNetworkItem {...item} key={item.id} />
        ))}
      </TokenCardGrid>
    </article>
  )
}


interface TokenItemProps extends TokenItem {
  balance?: string
  value: string
  muted?: boolean
  hidden?: boolean
}

const SelectTokenItem = (props: TokenItemProps) => {
  const { value, balance, muted, hidden } = props
  const { hideBalance, selectToken } = useSelectToken()

  return hidden ? null : (
    <button
      type="button"
      className={cx(styles.button, { muted })}
      onClick={() => selectToken(value)}
    >
      <TokenCard
        {...props}
        className={styles.item}
        balance={hideBalance ? undefined : balance}
        name={undefined /* Hide name */}
        value={undefined /* To avoid put the `option` value */}
      />
    </button>
  )
}




const SelectNetworkItem = (props: Network) => {
  const { id, name } = props
  const { selectNetwork } = useSelectNetwork()

  // return null
  return (
    <button
      type="button"
      className={styles.button}
      onClick={() => selectNetwork(id)}
    >
      <TokenCard
        {...props}
        className={styles.item}
        symbol={name}
        name={undefined /* Hide name */}
        value={undefined /* To avoid put the `option` value */}
      />
    </button>
  )
}




interface TokenContext {
  hideBalance: boolean
  selectToken: Props["onChange"]
}

const [useSelectToken, SelectTokenProvider] = createContext<TokenContext>("useSelectToken")



interface Props {
  value?: string
  onChange: (value: string) => void
  options: TokenGroupProps[]
  addonAfter: ReactNode // input
  checkbox?: ReactNode
}

const SelectToken = ({ value: selected, onChange, ...props }: Props) => {
  const { options, addonAfter, checkbox } = props
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const selectToken = (value: string) => {
    if (value !== selected) onChange(value)
    toggle()
  }

  const [keyword, setKeyword] = useState("")

  const items = flatten(Object.values(options.map(({ children }) => children)))
  const current = items.find((item) => item.value === selected)
  const byKeyword = items.filter((item) =>
    [item.value, item.symbol].some((k) =>
      k.toLowerCase().includes(keyword.toLowerCase())
    )
  )

  const empty = !byKeyword.length

  return (
    <SelectTokenProvider value={{ hideBalance: !checkbox, selectToken }}>
      <div className={styles.component}>
        <Flex>
          <button type="button" className={styles.toggle} onClick={toggle}>
            {current ? (
              <>
                <TokenIcon icon={current.icon} />
                {current.symbol}
              </>
            ) : (
              t("Select a coin")
            )}

            <ArrowDropDownIcon style={{ fontSize: 18 }} />
          </button>

          {addonAfter}
        </Flex>

        {isOpen && (
          <section>
            <Flex className={styles.search}>
              <SearchIcon />
              <input
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder=""
                autoComplete="off"
                autoFocus
              />
            </Flex>

            <Grid gap={20} className={cx(styles.list, { empty })}>
              {keyword ? (
                empty ? (
                  <Empty />
                ) : (
                  <TokenCardGrid>
                    {byKeyword.map((item) => (
                      <SelectTokenItem {...item} key={item.value} />
                    ))}
                  </TokenCardGrid>
                )
              ) : (
                <>
                  {checkbox && (
                    <section className={cx(styles.checkbox)}>
                      {checkbox}
                    </section>
                  )}

                  {options.map((option) => (
                    <SelectTokenGroup {...option} key={option.title} />
                  ))}
                </>
              )}
            </Grid>
          </section>
        )}
      </div>
    </SelectTokenProvider>
  )
}

export default SelectToken






interface NetworkContext {
  selectNetwork: PropsCrossChain["onChangeNetwork"]
}

const [useSelectNetwork, SelectNetworkProvider] = createContext<NetworkContext>("useSelectNetwork")


interface PropsCrossChain extends Omit<Props, "value"|"onChange"> {
  selectedToken?: string
  selectedNetworkID?: number
  networkOptions: NetworkGroupProps[]
  onChangeToken: (value: string) => void
  onChangeNetwork: (value: number) => void
}

export const SelectCrosschainToken = (props: PropsCrossChain) => {
  const { selectedToken, onChangeToken, options, addonAfter, checkbox } = props
  const { selectedNetworkID, onChangeNetwork, networkOptions } = props
  const { t } = useTranslation()

  const [keyword, setKeyword] = useState("")

  const [isOpenToken, setIsOpenToken] = useState(false)
  const toggleToken = () => {
    setKeyword("")
    setIsOpenNetwork(false)
    setIsOpenToken(!isOpenToken)
  }
  const selectToken = (value: string) => {
    if (value !== selectedToken) onChangeToken(value)
    toggleToken()
  }

  const [isOpenNetwork, setIsOpenNetwork] = useState(false)
  const toggleNetwork = () => {
    setKeyword("")
    setIsOpenToken(false)
    setIsOpenNetwork(!isOpenNetwork)
  }
  const selectNetwork = (value: number) => {
    if (value !== selectedNetworkID) onChangeNetwork(value)
    toggleNetwork()
  }


  const tokenItems = flatten(Object.values(options.map(({ children }) => children)))
  const currentToken = tokenItems.find((item) => item.value === selectedToken)
  const tokenByKeyword = tokenItems.filter((item) =>
    [item.value, item.symbol].some((k) =>
      k.toLowerCase().includes(keyword.toLowerCase())
    )
  )
  const emptyToken = !tokenByKeyword.length

  const networkItems = flatten(Object.values(networkOptions.map(({ children }) => children)))
  const currentNetwork = networkItems.find((item) => item.id === selectedNetworkID)
  const networkByKeyword = networkItems.filter((item) =>
    [item.name, item.alias].some((k) =>
      k.toLowerCase().includes(keyword.toLowerCase())
    )
  )
  const emptyNetwork = !networkByKeyword.length

  return (
    <SelectNetworkProvider value={{ selectNetwork }}>
      <SelectTokenProvider value={{ hideBalance: !checkbox, selectToken }}>
        <div className={styles.component}>
          <Flex>
          <button type="button" className={styles.toggle} onClick={toggleNetwork}>
              {currentNetwork ? (
                <>
                  <TokenIcon icon={currentNetwork.icon} />
                  {currentNetwork.name}
                </>
              ) : (
                t("Select network")
              )}

              <ArrowDropDownIcon style={{ fontSize: 18 }} />
            </button>

            <button type="button" className={styles.toggle} onClick={toggleToken}>
              {currentToken ? (
                <>
                  <TokenIcon icon={currentToken.icon} />
                  {currentToken.symbol}
                </>
              ) : (
                t("Select a coin")
              )}

              <ArrowDropDownIcon style={{ fontSize: 18 }} />
            </button>

            {addonAfter}
          </Flex>

          {isOpenNetwork && (
            <section>
              <Flex className={styles.search}>
                <SearchIcon />
                <input
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder=""
                  autoComplete="off"
                  autoFocus
                />
              </Flex>

              <Grid gap={20} className={cx(styles.list, { emptyNetwork })}>
                {keyword ? (
                  emptyNetwork ? (
                    <Empty />
                  ) : (
                    <TokenCardGrid>
                      {networkByKeyword.map((item) => (
                        <SelectNetworkItem {...item} key={item.id} />
                      ))}
                    </TokenCardGrid>
                  )
                ) : (
                  <>
                    {networkOptions.map((option) => (
                      <SelectNetworkGroup {...option} key={option.title} />
                    ))}
                  </>
                )}
              </Grid>
            </section>
          )}

          {isOpenToken && (
            <section>
              <Flex className={styles.search}>
                <SearchIcon />
                <input
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder=""
                  autoComplete="off"
                  autoFocus
                />
              </Flex>

              <Grid gap={20} className={cx(styles.list, { emptyToken })}>
                {keyword ? (
                  emptyToken ? (
                    <Empty />
                  ) : (
                    <TokenCardGrid>
                      {tokenByKeyword.map((item) => (
                        <SelectTokenItem {...item} key={item.value} />
                      ))}
                    </TokenCardGrid>
                  )
                ) : (
                  <>
                    {checkbox && (
                      <section className={cx(styles.checkbox)}>
                        {checkbox}
                      </section>
                    )}

                    {options.map((option) => (
                      <SelectTokenGroup {...option} key={option.title} />
                    ))}
                  </>
                )}
              </Grid>
            </section>
          )}

        </div>
      </SelectTokenProvider>
    </SelectNetworkProvider>
  )
}

