import { useTranslation } from "react-i18next"
import { Page, Card } from "components/layout"
import { ErrorBoundary, Wrong } from "components/feedback"

import GiveMeTokens from "./components/GiveMeTokens"
import CrossChainSwapContext from "./CrossChainSwapContext"
import SwapForm from "./SwapForm"



const CrossChainSwapTx = () => {
  const { t } = useTranslation()
  const description = t("")

  const fallback = () => <Card><Wrong/></Card>

  return (
    <Page title={t("Cross-Chain Swap")} small extra={description}>
      <GiveMeTokens></GiveMeTokens>
      <ErrorBoundary fallback={fallback}>
        <CrossChainSwapContext>
          <SwapForm />
        </CrossChainSwapContext>
      </ErrorBoundary>
    </Page>
  )
}

export default CrossChainSwapTx
