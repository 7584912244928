import { HTMLAttributes, useState } from "react"
import classNames from "classnames/bind"
import styles from "./TokenIcon.module.scss"

import gedexIcon from "styles/images/gedex/gedex_symbol.svg"

const cx = classNames.bind(styles)

interface Props extends HTMLAttributes<HTMLImageElement> {
  token?: string
  icon?: string
  size?: number | "inherit"
}

const TokenIcon = ({ icon, size, ...rest }: Props) => {
  const [isError, setIsError] = useState(false)
  const defaultIcon = gedexIcon

  const src = !icon || isError ? defaultIcon : icon
  const sizes = size === "inherit" ? undefined : { width: size ?? 24, height: size ?? 24 }

  const attrs = { ...rest, ...sizes, src, className: cx(styles.icon, size) }
  return <img {...attrs} onError={() => setIsError(true)} alt="" />
}

export default TokenIcon
