import { useTranslation } from "react-i18next"
import { useAccount, useNetwork } from 'wagmi'

import { validNetworkID, networkIcons } from 'config/networks'

import styles from "./NetworkName.module.scss"


const NetworkName = () => {
  const { t } = useTranslation()
  const { isConnected } = useAccount()
  const { chain } = useNetwork()

  const isValidNetwork = chain && validNetworkID.includes(chain.id)
  const netIcon = chain && networkIcons[chain.id]

  if (!isConnected) return <div/>

  return (
    <div className={styles.component}>
      <img src={netIcon} style={{height: "24px", paddingRight: "10px"}}/>
      {isValidNetwork ? chain?.name : t("Wrong network")}
    </div>
  )
}


export default NetworkName
