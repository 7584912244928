import mainnetContracts from "./deployments/mainnet_contracts_info.json"
import testnetContracts from "./deployments/testnet_contracts_info.json"

import { isTesting, defaultNetworkID } from "./networks"


const contracts = isTesting ? testnetContracts : mainnetContracts


export const getGEDEXContract = (networkID) => {
    // console.log("[CONFIG][contracts][getGEDEXContracts] networkID, defaultID", networkID, defaultNetworkID)
    const networkData = contracts[networkID] ?? contracts[defaultNetworkID]
    // console.log("[CONFIG][contracts][getGEDEXContracts] network data", networkData)
    return networkData.gedex
}

export const getGiveMeTokensContract = (networkID) => {
    const networkData = contracts[networkID] ?? contracts[defaultNetworkID]
    
    return networkData.givetokens
}


export const getGDXAddress = (networkID) => {
    const networkData = contracts[networkID] ?? contracts[defaultNetworkID]
    return networkData.tokens.gdx
}

